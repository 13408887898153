import { Spinner, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import { getDoc, doc } from "firebase/firestore";
import {db} from '../../config/firebase'
import { Link } from "react-router-dom";

export default function Blog(){
    const [blog, setBlog] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const getBlog = async() =>{
        setLoading(true)
        try{
            const q = doc(db, 'misc', "aboutus")
            const querySnapshot = await getDoc(q);
            setBlog(querySnapshot.data())
        }catch(error){
            setError('Something went wrong...')
        }
        setLoading(false)
    }

    useEffect(()=>{
        getBlog()
    }, [])

    return (
        <div style={{fontFamily: "Verdana", background:'linear-gradient(#adebeb, #ffffff)'}}>
        <div style={{height:'1px', backgroundColor:'white'}}></div>
        <div style={{margin:'auto'}}>
            {
                blog
                ?
                <div>
                    <div style={{textAlign:'center', marginTop:'40px'}}>
                        <p>HI I'M</p>
                        <h3>{blog.name}</h3>
                        <p>{blog.tagline}</p>
                        <Button variant="success"><Link  to="/contact" style={{textDecoration:'none', color:'whitesmoke'}}>Get in touch</Link></Button>
                        <div style={{marginTop:'40px'}}>
                            <img style={{maxHeight:'240px'}} src={blog.purl1} alt="prabhakar"/>
                            <img style={{maxHeight:'240px'}} src={blog.purl2} alt="prabhakar"/>
                        </div>
                        <div style={{marginTop:'40px'}}>
                            <h3><FontAwesomeIcon icon={faIdCard}/> About</h3>
                            <p style={{maxWidth:'600px', margin:'auto', textAlign:'justify'}}>{blog.about}</p>
                        </div>
                    </div>
                    
                </div>
                :!error&& loading
                ?
                <div style={{width:'100%'}}>
                    <div style={{textAlign:'center'}}>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Loading...
                    </div>
                </div>
                :
                <>
                <h3 style={{textAlign:'center'}}>Something went wrong...</h3>
                </>
            }
        </div>
        </div>
    );
}