import { useState, useEffect } from 'react';
import {Card, Spinner, Container} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import { getDocs, collection, query, where } from "firebase/firestore";
import {db} from '../../config/firebase'
import './styles.css'
import SideBar from '../../components/header/sidebar';

export default function Home(){

    const [blogs, setBlogs] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const params = useParams()

    const fetchBlogs = async() =>{
        setLoading(true)
        try{
            const q = query(collection(db, 'blogs'), where('cat', '==', params.cat))
            const querySnapshot = await getDocs(q);
            let temp = []
            querySnapshot.forEach((doc)=>{
                temp.push(doc.data())
            })
            setBlogs(temp)
        }catch(error){
            setError('Something went wrong...')
            console.log(error)
        }
        setLoading(false)
    }

    useEffect(()=>{
        fetchBlogs()
    }, [params])

    return (
        <div className='outer-container'>
            <Container>
                <div className='main-div'>
                    <div className='blog-div'>
                        <h3>{`#${params.cat}`}</h3>
                        <div className="post-container">
                            {
                                blogs && blogs.length
                                ?
                                <>
                                {
                                    blogs.map(doc =>
                                        <Link to={`/blog/${doc.id}`} key={doc.id} style={{textDecoration:'none'}}>
                                            <Card style={{ width: '100%', marginBottom:'10px'}}>
                                                <Card.Img variant="top" src={doc.url} style={{maxHeight:'400px'}}/>
                                                <Card.Title style={{ margin:'4px'}}>{doc.title}</Card.Title>
                                            </Card>
                                        </Link>
                                    )
                                }
                                </>
                                :!error&& loading
                                ?
                                <div style={{width:'100%'}}>
                                    <div style={{textAlign:'center'}}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </div>
                                </div>
                                :
                                <>
                                <h3 style={{textAlign:'center'}}>Something went wrong...</h3>
                                </>
                            }
                        </div>
                    </div>
                    <div className='cat-div'>
                        <SideBar/>
                    </div>
                </div>
            </Container>       
        </div>
    );
}