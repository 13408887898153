import React from 'react'
import { Card } from 'react-bootstrap';
import {Link} from 'react-router-dom'

function SideBar() {

  return (
    <Card style={{padding:'10px'}}>
      <h3>Quick Access</h3>
      <p><Link to={`/`} style={{textDecoration:'none'}}>#Home</Link></p>
      <p><Link to={`/about`} style={{textDecoration:'none'}}>#About</Link></p>
      <p><Link to={`/contact`} style={{textDecoration:'none'}}>#Contact</Link></p>
      <p><Link to={`/store`} style={{textDecoration:'none'}}>#Store</Link></p>
      <p><Link to={`/videos`} style={{textDecoration:'none'}}>#videos</Link></p>
      <h3>Blog Categories</h3>
      <p><Link to={`/business`} style={{textDecoration:'none'}}>#Business</Link></p>
      <p><Link to={`/it`} style={{textDecoration:'none'}}>#Information Technology</Link></p>
      <p><Link to={`/marketing`} style={{textDecoration:'none'}}>#Marketing</Link></p>
      <p><Link to={`/fin-analysis`} style={{textDecoration:'none'}}>#Financial analysis</Link></p>
      <p><Link to={`/agri-business`} style={{textDecoration:'none'}}>#Agri business</Link></p>        
    </Card>
  );
}

export default SideBar;