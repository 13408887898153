import { Button, Spinner, Card, Container } from "react-bootstrap";
import { useParams} from "react-router-dom";
import { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import {db} from '../../config/firebase'
import './styles.css'

export default function Blog(){
    const [blog, setBlog] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const params = useParams()

    const getBlog = async() =>{
        setLoading(true)
        try{
            const q = doc(db, 'blogs', params.id)
            const querySnapshot = await getDoc(q);
            setBlog(querySnapshot.data())
        }catch(error){
            setError('Something went wrong...')
        }
        setLoading(false)
    }

    useEffect(()=>{
        getBlog()
    }, [params])

    return (
        <div className='outer-container'>
            <Container>
                <div className='main-div'>
                    <div className='blog-div' style={{marginRight:'10px', marginBottom:'10px'}}>
                        <div className="post-container">
                            {
                                blog
                                ?
                                <>
                                        <h3>{blog.title}</h3>
                                        <div style={{whiteSpace: "pre-wrap"}}>
                                            <p>{blog.intro}</p>
                                            <div>
                                                <img className="col-sm" style={{width: "100%", marginLeft: "auto", marginRight: "auto"}} src={blog.url} alt="Profile" />
                                            </div>
                                            {
                                                blog.body.split("<br/><br/>").map(item =>
                                                    <p><br/>{item}</p>
                                                )
                                            }
                                            {/* <p>{blog.body.split("<br/><br/>")[1]}</p> */}
                                            <br/>
                                            <div>{blog.conclusion}</div>
                                            <div>Date Posted- {blog.posted}</div>
                                        </div>
                                        <br/>
                                        <Button variant="outline-success">
                                            <a style={{color: "black", textDecoration: "none"}}
                                                href={`https://api.whatsapp.com/send?text=https://rblogs.in/blog/${blog.id}`} 
                                                data-action="share/whatsapp/share">
                                                <i className="fa fa-whatsapp"></i> Share on WhatsApp
                                            </a>
                                        </Button>
                                </>
                                :!error&& loading
                                ?
                                <div style={{width:'100%'}}>
                                    <div style={{textAlign:'center'}}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </div>
                                </div>
                                :
                                <>
                                <h3 style={{textAlign:'center'}}>Something went wrong...</h3>
                                </>
                            }
                        </div>
                    </div>
                    <div className='cat-div'>
                        <Card style={{padding:'10px'}}>
                            <h3>Tags</h3>
                            <p>#Business</p>
                            <p>#Information Technology</p>
                            <p>#Marketing</p>
                            <p>#Financial analysis</p>
                            <p>#Agri business</p>                     
                        </Card>
                    </div>
                </div>
            </Container>
        </div>
    );
}