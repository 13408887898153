import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import Contact from "./pages/contact/contact";
import About from "./pages/about/about";
import Blogs from "./pages/blogs/blogs";
import Blog from "./pages/blogs/blog";
import Store from "./pages/store/store";
import Video from "./pages/video/video";
import Play from "./pages/video/play";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/store' element={<Store/>} />
      <Route path='/videos' element={<Video/>} />

      <Route path='/:cat' element={<Blogs/>} />

      <Route path='/blog/:id' element={<Blog/>} />
      <Route path='/videos/:id' element={<Play/>} />
            
      <Route path="*" element={
        <main style={{ padding: "5rem" }}>
          <h1>There's nothing here!</h1>
        </main>
      }/>
    </Routes>
  );
}

export default App;