import React, {useState} from 'react'
import { Navbar, Container, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReorder } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import './styles.css'

function Header() {
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }

  return (
    <Navbar expand="lg" style={{background: "linear-gradient(#33cccc, #adebeb)"}}>
        <Container fluid>
          <Navbar.Brand href="/" style={{fontFamily: "cursive", color:'white'}}>rblogs</Navbar.Brand>
          <div>
            <Button variant="outline-light" style={{width:'100px', borderRadius:'40px', textAlign:'start', marginRight:'10px'}}>🔍Search</Button>
            <Button className='btnh' variant="outline-light" onClick={() => handleShow()}><FontAwesomeIcon icon={faReorder}/></Button>
          </div>
        </Container>
        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
          <Modal.Header closeButton style={{background: "linear-gradient(#33cccc, #adebeb)"}}>
            <Modal.Title>Quick Access</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p><Link to={`/`} style={{textDecoration:'none'}} onClick={() => setShow(false)}>#Home</Link></p>
              <p><Link to={`/about`} style={{textDecoration:'none'}} onClick={() => setShow(false)}>#About</Link></p>
              <p><Link to={`/contact`} style={{textDecoration:'none'}} onClick={() => setShow(false)}>#Contact</Link></p>
              <p><Link to={`/store`} style={{textDecoration:'none'}} onClick={() => setShow(false)}>#Store</Link></p>
              <p><Link to={`/videos`} style={{textDecoration:'none'}} onClick={() => setShow(false)}>#videos</Link></p>     
          </Modal.Body>
        </Modal>
    </Navbar>
  );
}

export default Header;