import { useState, useEffect } from 'react';
import {Card, Spinner, Container} from 'react-bootstrap'
import { useParams} from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import {db} from '../../config/firebase'
import './styles.css'
import SideBar from '../../components/header/sidebar';

export default function Play(){
    
    const [blog, setBlog] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')


    const params = useParams()

    const getBlog = async() =>{
        setLoading(true)
        try{
            const q = doc(db, 'videos', params.id)
            const querySnapshot = await getDoc(q);
            setBlog(querySnapshot.data())
        }catch(error){
            setError('Something went wrong...')
        }
        setLoading(false)
    }

    useEffect(()=>{
        getBlog()
    }, [params])

    return (
        <div className='outer-container'>
            <Container>
                <div className='main-div'>
                    <div className='blog-div'>
                        <div className="post-container">
                            {
                                blog
                                ?
                                <>
                                <Card className='blog'>
                                    <iframe 
                                        width="100%" 
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${blog.url}`}
                                        title="YouTube video player" 
                                        allow="accelerometer; 
                                        autoplay; 
                                        clipboard-write; 
                                        encrypted-media; 
                                        gyroscope; 
                                        picture-in-picture; 
                                        web-share" allowFullScreen>
                                    </iframe>
                                </Card>
                                </>
                                :!error&& loading
                                ?
                                <div style={{width:'100%'}}>
                                    <div style={{textAlign:'center'}}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </div>
                                </div>
                                :
                                <>
                                <h3 style={{textAlign:'center'}}>Something went wrong...</h3>
                                </>
                            }
                        </div>
                    </div>
                    <div className='cat-div'>
                        <SideBar/>
                    </div>
                </div>
            </Container>       
        </div>
    );
}