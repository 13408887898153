import { useState, useEffect } from 'react';
import {Card, Spinner, Container} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { getDocs, collection } from "firebase/firestore";
import {db} from '../../config/firebase'
import './styles.css'
import SideBar from '../../components/header/sidebar';

export default function Home(){
    
    const [cat, setCat] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')


    const getCat = async() =>{
        setLoading(true)
        try{
            const q = collection(db, 'blogs')
            const querySnapshot = await getDocs(q);
            let temp = []
            querySnapshot.forEach((doc)=>{
                temp.push(doc.data())
            })
            setCat(temp)
        }catch(error){
            setError('Something went wrong...')
        }
        setLoading(false)
    }

    useEffect(()=>{
        getCat()
    }, [])

    return (
        <div className='outer-container'>
            <Container>
                <div className='main-div'>
                    <div className='blog-div'>
                        <h3>Recent blogs</h3>
                        <div className="post-container">
                            {
                                cat && cat.length
                                ?
                                <>
                                {
                                    cat.map(doc =>
                                        <Card className='blog' key={doc.id}>
                                            <Link to={`/blog/${doc.id}`} style={{textDecoration:'none'}}>
                                                <Card.Img variant="top" src={doc.url} style={{maxHeight:'400px'}}/>
                                                <Card.Title style={{ margin:'4px'}}>{doc.title}</Card.Title>
                                            </Link>
                                        </Card>
                                    )
                                }
                                </>
                                :!error&& loading
                                ?
                                <div style={{width:'100%'}}>
                                    <div style={{textAlign:'center'}}>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </div>
                                </div>
                                :
                                <>
                                <h3 style={{textAlign:'center'}}>Something went wrong...</h3>
                                </>
                            }
                        </div>
                    </div>
                    <div className='cat-div'>
                        <SideBar/>
                    </div>
                </div>
            </Container>       
        </div>
    );
}