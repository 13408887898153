import { Spinner, Container, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import {db} from '../../config/firebase'
import './styles.css'
import SideBar from '../../components/header/sidebar'

export default function Blog(){
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const submitForm = async(e) =>{
        e.preventDefault()
        const email = e.target.email.value
        const comment = e.target.comment.value

        setLoading(true)
        try{
            await setDoc(doc(db, "comments", email), {
                email: email,
                comment: comment
            });
            setError('Saved successfully...')
        }catch(error){
            setError('Something went wrong...')
        }
        setLoading(false)
    }

    return (
        <div className='outer-container'>
        <Container>
            <div className='main-div'>
                <div className='blog-div'>
                    <div className="post-container">
                        <h3>Please write your feedback, we will revert back</h3>
                        {error}
                        <Form onSubmit={(e)=>submitForm(e)}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control name="email" type="email" placeholder="name@example.com" required/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Your comments</Form.Label>
                                <Form.Control name="comment" as="textarea" rows={4} required/>
                            </Form.Group>
                            <Button variant='outline-success' type="submit" style={{marginBottom:'20px'}}>
                                {
                                    !loading
                                    ?
                                    'Submit'
                                    :
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
                                        Submitting...
                                    </>
                                }
                            </Button>
                        </Form>
                    </div>
                </div>
                <div className='cat-div'>
                    <SideBar/>
                </div>
            </div>
        </Container>       
    </div>
    );
}